import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MyLink from '../link';
// import Select from '../input';
import Img from '../Img';
import { NavWrapper } from './style';
import Logo from './logo.png';
import bars from './bars.svg';

const NavBar = (props) => {
  const [toggleNav, setToggleNav] = useState(false);

  const handleToggle = () => {
    setToggleNav(!toggleNav);
  };
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(null);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    localStorage.setItem('language', language ? language : '');
  }, [language]);

  // useEffect(() => {
  //     const selected = localStorage.getItem('lng');
  //     setLanguage(selected);
  // }, [language]);

  const handleOnChange = (e) => {
    if (e.target.value == 'deu') {
      changeLanguage('deu');
      setLanguage('deu');
    } else if (e.target.value == 'eng') {
      changeLanguage('eng');
      setLanguage('eng');
    }
  };

  return (
    <NavWrapper display={toggleNav ? 'flex' : 'none'}>
      <div class="nav-container">
        <div className="nav-image-wrapper">
          <div className="nav-image">
            <Img src={Logo} alt="AdminCat logo" />
          </div>
        </div>

        <div className="nav-buttons">
          {/* <Select onChange={handleOnChange} /> */}
          <MyLink white border to="/login">
            {t('Log in')}
          </MyLink>
          <MyLink blue to="/register">
            {t('Get Started')}
          </MyLink>
        </div>
        <div class="hambugger" onClick={handleToggle}>
          <Img src={bars} />
        </div>
      </div>
    </NavWrapper>
  );
};
export default NavBar;
