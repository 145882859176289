import React, { useMemo } from 'react';
import compose from 'lodash/fp/compose';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
} from 'recharts';
import 'chart.js/auto';

import { withTranslation, withUserPreferences } from 'components/hoc';

const Top5Contracts = ({ data, tr, fn }) => {
  const bgColors = [
    ['rgba(255, 99, 132, 1)'],
    ['rgba(255, 99, 132, 1)', 'rgba(255, 159, 64, 1)'],
    ['rgba(255, 205, 86, 1)', 'rgba(255, 99, 132, 1)', 'rgba(255, 159, 64, 1)'],
    [
      'rgba(255, 205, 86, 1)',
      'rgba(255, 99, 132, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(255, 159, 64, 1)',
    ],
    [
      'rgba(54, 162, 235, 1)',
      'rgba(255, 205, 86, 1)',
      'rgba(255, 99, 132, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(255, 159, 64, 1)',
    ],
  ];

  const data_ = [...(data || [])];

  const getColor = (length, index) => {
    if (length <= bgColors.length) {
      return bgColors[length - 1][index];
    }

    return bgColors[bgColors.length - 1][index % bgColors.length];
  };

  let ctx;

  const measureText14HelveticaNeue = (text) => {
    if (!ctx) {
      ctx = document.createElement('canvas').getContext('2d');
      ctx.font = "14px 'Helvetica Neue";
    }

    return ctx.measureText(text).width;
  };

  const BAR_AXIS_SPACE = 30;

  const maxTextWidth = useMemo(
    () =>
      data_.reduce((acc, cur) => {
        const value = cur['transactions'];
        const width = measureText14HelveticaNeue(value.toLocaleString());
        if (width > acc) {
          return width;
        }
        return acc;
      }, 0),
    [data_, 'transactions'],
  );

  return (
    <ResponsiveContainer width={'100%'} height={50 * data_.length} debounce={50}>
      <BarChart
        data={data_}
        layout="vertical"
        margin={{ left: 2, right: maxTextWidth + (BAR_AXIS_SPACE - 8) }}
      >
        <XAxis hide axisLine={false} type="number" />

        <YAxis
          yAxisId={0}
          orientation="left"
          dataKey={'financial_account_name'}
          type="category"
          axisLine={true}
          style={{ backgroundColor: 'green', width: '50%' }}
          tickLine={true}
          mirror
          hide
          tick={{
            transform: `translate(${maxTextWidth + BAR_AXIS_SPACE}, 0)`,
            attributeName: 'testing',
          }}
        />

        <YAxis
          orientation="right"
          yAxisId={1}
          dataKey={'transactions'}
          type="category"
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) => fn(value?.toFixed(2))}
          mirror
          tick={{
            transform: `translate(${maxTextWidth + BAR_AXIS_SPACE}, 0)`,
          }}
        />

        <Tooltip
          formatter={(payload) => {
            return fn(payload?.toFixed(2));
          }}
          labelFormatter={(label, payload) => {
            return (
              <>
                <div
                  style={{
                    fontWeight: '300',
                    fontSize: 12,
                    whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word',
                  }}
                >
                  {tr('Description')}: {tr(payload?.[0]?.payload?.description)}
                </div>
                <div
                  style={{
                    fontWeight: '400',
                    fontSize: 12,
                    whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word',
                  }}
                >
                  ({tr(label)})
                </div>
              </>
            );
          }}
        />

        <Bar dataKey={'transactions'} minPointSize={2} barSize={32}>
          {data_.map((d, idx) => {
            return (
              <Cell
                key={d['financial_account_name']}
                fill={getColor(data_.length, idx)}
              />
            );
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default compose(withTranslation, withUserPreferences)(Top5Contracts);
