// /* eslint-disable */
import React, { Component } from 'react';
import compose from 'lodash/fp/compose';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { withMsgInfo, withTranslation, withAccount } from '../hoc';
import {
  ExclamationTriangle,
  Check2Circle,
  ExclamationCircle,
  ExclamationOctagon,
} from 'react-bootstrap-icons';
import TrialPeriod from 'components/TrialPeriod';

const Container = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  z-index: 500;
  width: 100%;
  box-shadow: 0 1px 1px 0 rgba(3, 3, 3, 0.05);
  // background-color: var(--admincat-color-grey-0);
  background: ${({ isAuthenticated }) =>
    isAuthenticated ? 'var(--admincat-color-grey-4)' : '#f5f7fb'};
  position: sticky;
  top: 80px;
`;

const NotificationBar = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  margin-left: 80px;
  // background: var(--admincat-color-grey-4);
  // background: ${({ isAuthenticated }) => isAuthenticated ? 'var(--admincat-color-grey-4)' : '#f5f7fb'};
  padding: 0.5em 0.5em;
  z-index: 1000;
  height: 25px; // adjust the height here

  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 80px;
  }
`;

const MessageContent = styled.div`
  display: flex;
  align-items: center;
  width: 49%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const MessageText = styled.p`
  margin: 0;
  padding: 0;
  font-size: clamp(14px, 4vw, 14px);
  ${({ type }) => typeStyles[type]}
`;

const Icon = styled.span`
  margin-right: 10px;
  ${({ type }) => css`
    color: ${typeStyles[type].color};
  `}
`;

const DANGER_COLOR = '#f60a0a';
const SUCCESS_COLOR = '#439f6e';
const INFO_COLOR = '#0c5460';
const WARNING_COLOR = '#856404';

const typeStyles = {
  success: css`
    color: ${SUCCESS_COLOR};
  `,
  warning: css`
    color: ${WARNING_COLOR};
  `,
  info: css`
    color: ${INFO_COLOR};
  `,
  danger: css`
    color: ${DANGER_COLOR};
  `,
};

class MsgInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      msg: [],
      success: false,
      show: false,
      navigationCounter: 0,
    };
  }

  componentDidUpdate(prevProps) {
    // Compare the relevant prop to see if it changed
    if (this.props.msgInfo.msg !== prevProps.msgInfo.msg) {
      if (this.state.navigationCounter >= 0) {
        // at this point we need to determine
        // if we want the current content in the navigation
        // to persist.

        let shouldOveride = this.props.msgInfo?.msg.length > 0;
        if (shouldOveride) {
          this.setData({ ...this.props.msgInfo, show: true });
        } else if (!this.state.show && this.state.navigationCounter == 0) {
          let isTrialPeriodLeft = this.isTrialPeriodLeft();
          this.setData({
            msg: [],
            navigationCounter: 0,
            success: false,
            show: isTrialPeriodLeft || false,
          });
        }
      } else {
        this.setData({ ...this.props.msgInfo, show: true });
      }
    }

    // this condition is expected to execute
    // only on the initial update of the component
    // when the value of trial period moves from
    // undefined to defined
    if (
      prevProps?.account?.user?.trial_period_left == undefined &&
      this.props?.account?.user?.trial_period_left != undefined
    ) {
      if (this.props.account?.user?.trial_period_left > 0) {
        this.setState({ show: true });
      }
    }

    // this condition ensures that if the trial period is left
    // the menu bar should always be visible
    if (!this.state.show && this.isTrialPeriodLeft()) {
      this.setState({ show: true });
    }
  }

  setData(data) {
    let { success, msg, navigationCounter, show } = data;
    // Set navigation counter based on message type
    // Success messages are usually persisted across route changes
    // this ensures that the notification bar is visible when you move from the form page to the index page
    // of the resource
    navigationCounter = navigationCounter || (success ? 2 : 1);

    if (this.isTrialPeriodLeft()) {
      // if trial period is active, always set state to show the notificaion bar
      show = true;
    } else {
      show = show || false;
    }

    this.setState({ success, msg, navigationCounter, show });
  }

  getTrialPeriodFromProps() {
    return this.props?.account?.user?.trial_period_left;
  }

  isTrialPeriodLeft() {
    const trialPeriodLeft = this.getTrialPeriodFromProps();
    return typeof trialPeriodLeft === 'number' && trialPeriodLeft > 0;
  }

  componentDidMount() {
    var trialPeriodLeft = this.isTrialPeriodLeft();
    if (this.props.history) {
      this.unlisten = this.props.history.listen(() => {
        if (this.state.navigationCounter > 0) {
          if (trialPeriodLeft) {
            this.setState((prevState) => ({
              navigationCounter: prevState.navigationCounter - 1,
              show: true,
            }));
          } else {
            this.setState((prevState) => ({
              navigationCounter: prevState.navigationCounter - 1,
              show: prevState.navigationCounter > 1,
            }));
          }
        } else {
          this.setState({
            navigationCounter: 0,
            show: trialPeriodLeft,
            success: false,
            msg: [],
          });
        }
      });
    }
  }

  componentWillUnmount() {
    if (typeof this.unlisten === 'function') {
      this.unlisten();
    }
  }

  render() {
    const { tr, account } = this.props;
    const trialPeriod = account?.user?.trial_period_left;
    let { success, warning, danger, info, msg } = this.state;

    const type = success
      ? 'success'
      : warning
      ? 'warning'
      : info
      ? 'info'
      : danger
      ? 'danger'
      : 'danger';
    const icon =
      type === 'success' ? (
        <Check2Circle color={SUCCESS_COLOR} />
      ) : type === 'warning' ? (
        <ExclamationOctagon color={WARNING_COLOR} />
      ) : type === 'info' ? (
        <ExclamationCircle color={INFO_COLOR} />
      ) : (
        <ExclamationTriangle color={DANGER_COLOR} />
      );

      const isAuthenticated = account?.user?.isAuth;

    return (
      <Container show={this.state.show} type={type} isAuthenticated={isAuthenticated}>
        <NotificationBar show={this.state.show} type={type} >
          <MessageContent>
            {msg.length > 0 && (
              <>
                <Icon type={type}>{icon}</Icon>
                <MessageText type={type}>{tr(msg)}</MessageText>
              </>
            )}
          </MessageContent>
          <TrialPeriod daysLeft={trialPeriod} tr={tr} />
        </NotificationBar>
      </Container>
    );
  }
}

export default compose(
  withRouter,
  withMsgInfo,
  withTranslation,
  withAccount,
)(MsgInfo);
