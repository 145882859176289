import React, { useEffect } from 'react';
import compose from 'lodash/fp/compose';
import styled from 'styled-components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.css';
import { useLocation, Link } from 'react-router-dom';
import {
  withMail,
  withAccount,
  withTranslation,
  withUserDefaultSpace,
  withFormValidation,
} from '../../../hoc';
import { FormValidationContext } from '../../../hoc/Preload/FormValidation';
import {
  FormModal,
  Form,
  Input,
  // FileInput,
  Button,
  // TextArea,
  // TextEditor,
  RichTextEditor,
  // Editor,
  SquareCheckbox,
  ButtonLink,
} from '../../../common/FormItems';
import { FileEarmark, Square, XCircleFill } from 'react-bootstrap-icons';
import { setMsgInfo } from '../../../../actions';
import { useDispatch } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react';

const Wrapper = styled.div``;

const Flex = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || ''};
  align-items: ${({ alignItems }) => alignItems || ''};
  flex-wrap: wrap;
`;

const FileAvatar = styled.a`
  margin: 5px 5px;
  font-style: italic;
  font-size: 0.9rem;
  align-items: center;
  display: block;
  &:last-child {
    margin-top: 0;
  }
`;

Flex.displayName = 'Flex';

const InputSection = styled(Flex)`
  margin: 2px 0;
`;

const StyledButtonLink = styled(ButtonLink)`
  span {
    text-transform: uppercase;
  }
`;

const InputWrapper = styled(Flex)`
  margin-right: 5px;
  justify-content: space-between;
  max-width: 100%;
  align-items: center;
  flex-wrap: wrap;

  // Style specifically for checkbox group
  &.checkbox-group {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  > div {
    flex: 1;
  }
`;

const Label = styled.label`
  color: var(--admincat-dark-color);
  font-size: 1rem;
  font-weight: 600;
  margin-right: 10px;
  align-self: start;
  width: 75px;

  // Style for labels above checkboxes
  &.checkbox-label {
    width: auto;
    align-self: center;
    margin-bottom: 5px;
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  && {
    // color: var(--admincat-dark-color) !important;
    text-transform: capitalize;
    width: 8.0625rem;
    height: 2.5rem;
    font-weight: 500;
    border-radius: 7px;
    transition: all ease-in-out 300ms;
    background: #4c81be;
    border: 1px solid #1d579b;
    box-sizing: border-box;
    font-style: normal;
    font-size: 1rem;
    line-height: 1rem;
    color: #ffffff;
    cursor: pointer;
    margin-right: 1rem;
  }
`;

const mailFormSchema = {
  from_mail: {
    validation: 'required|email',
    message: {
      email: "Sender's email is invalid",
      required: "Sender's email is required",
    },
  },
  to_mail: {
    validation: 'required',
    message: {
      required: "Reciever's email is required",
    },
  },
  subject: {
    validation: 'required|min:3',
  },
  message: {
    validation: 'required|min:3',
    Link,
  },
};

const MAX_SIZE_OF_ATTACHMENTS = 8485760; // this is the file size for attachments the django mail client can handle without crashing

const getFields = (formData) => {
  return [
    {
      type: 'email',
      name: 'from_mail',
      label: 'From',
      placeholder: 'Your email',
      disabled: true,
    },
    {
      type: 'checkbox',
      name: 'is_paying',
      label: 'Send to Paying Users',
    },
    {
      type: 'checkbox',
      name: 'is_admin',
      label: 'Send to Admin Users',
    },
    {
      type: 'checkbox',
      name: 'is_verified',
      label: 'Send to Verified Users',
    },
    {
      type: 'text',
      name: 'subject',
      label: 'Subject',
      placeholder: 'Subject',
    },
    {
      type: 'textarea',
      name: 'message',
      label: 'Message',
      placeholder: 'Your message...',
    },
  ].map((data) => ({
    ...formData,
    ...data,
    value: formData[data.name],
    checked: formData[data.name], // For checkbox fields
  }));
};

const InputFieldType = {
  email: Input,
  text: Input,
  textarea: RichTextEditor,
  checkbox: SquareCheckbox,
};

const StyledSquareCheckbox = styled(SquareCheckbox)`
  label.SquareCheckboxLabel {
    display: block;
    font-weight: 600;
    font-size: 0.875rem;
    color: var(--admincat-medium-color);
    padding-left: 0px;
  }
`;

const Fields = (props) => {
  const checkboxFields = getFields({ ...props }).filter(
    (f) => f.type === 'checkbox',
  );
  const otherFields = getFields({ ...props }).filter((f) => f.type !== 'checkbox');

  return (
    <>
      <InputWrapper className="checkbox-group">
        {checkboxFields.map(({ label, tr, ...rest }) => (
          <div key={label}>
            {/* <Label className="checkbox-label">{tr(label)}</Label> */}
            <StyledSquareCheckbox label={label} {...rest} />
          </div>
        ))}
      </InputWrapper>
      {otherFields.map(({ type, label, tr, ...rest }) => {
        const InputField = InputFieldType[type];
        return (
          <InputWrapper key={label}>
            <Label>{tr(label)}:</Label>
            <InputField type={type} {...rest} />
          </InputWrapper>
        );
      })}
    </>
  );
};

const Compose = (props) => {
  const {
    openComposeForm,
    toggleComposeForm,
    onMailChange: onChange,
    mailFormData,
    sendMail,
    sendMailLoading,
    account: {
      user: { email: personalEmail },
    },
    userDefaultSpace: { space_detail },
    tr,
    basePath,
  } = props;
  const location = useLocation();
  const { selectedMail = {}, type, previousPath = '' } = location.state || {};

  const dispatch = useDispatch();

  const spaceEmail = space_detail && space_detail.email;

  const { validateForm } = React.useContext(FormValidationContext);

  const from_mail = spaceEmail || personalEmail;

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);

  const handleFileUploadClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    const chosenFiles = Array.prototype.slice.call(event.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let size = 0;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });

    uploaded.map((file) => (size += file.size));
    if (size > MAX_SIZE_OF_ATTACHMENTS) {
      dispatch(
        setMsgInfo({
          success: false,
          msg: ['Attachments size must be less than 8 MB.'],
        }),
      );
      return;
    }

    setUploadedFiles(uploaded);
    onChange({ target: { name: 'attachments', value: files } });
    dispatch(setMsgInfo({ success: true, msg: ['Files have been appended.'] }));
  };

  const removeFile = (fileName) => {
    const files = uploadedFiles.filter((file) => file.name != fileName);
    setUploadedFiles(files);
    onChange({ target: { name: 'attachments', value: files } });
  };

  useEffect(() => {
    if (spaceEmail) {
      onChange({ target: { name: 'from_mail', value: from_mail } });
    }
    if (type == 'fwd') {
      onChange({
        target: { name: 'subject', value: `Fwd: ${selectedMail?.subject}` },
      });
    }
    if (type == 'reply') {
      onChange({
        target: { name: 'subject', value: `Re: ${selectedMail?.subject}` },
      });
      onChange({ target: { name: 'to_mail', value: selectedMail?.from_mail } });
    }
    return () => null;
  }, [spaceEmail, mailFormData.from_mail]);

  return (
    <Wrapper key={basePath} style={{ height: '100vh' }} className="form-wrapper">
      <div className="row">
        <div className="col-7 ml-10 mt-30" style={{ position: 'relative' }}>
          <div
            className="ml-0"
            style={{ position: 'absolute', top: '-40px', left: '0', zIndex: 200 }}
          >
            <StyledButtonLink
              to={{
                pathname: `/dashboard`,
              }}
              className="option-btn"
              icon="angle double left"
              name="Go Back"
            />
          </div>
          <br />
          <Form
            title="Compose Mail"
            open={openComposeForm}
            toggleForm={() => toggleComposeForm(null)}
            formData={mailFormData}
            validationSchema={mailFormSchema}
            renderActionButtons={() => null}
          >
            <Flex justifyContent="flex-end"></Flex>
            <InputSection flexDirection="column">
              <Fields
                {...mailFormData}
                selectedMail={selectedMail}
                tr={tr}
                onChange={onChange}
                key={openComposeForm}
              />
              <Flex justifyContent="flex-end" alignItems="flex-end">
                <Popup
                  content={tr('Attach documents')}
                  size="mini"
                  trigger={
                    <Icon
                      name="attach"
                      onClick={handleFileUploadClick}
                      size="large"
                      style={{ marginRight: '1%' }}
                    />
                  }
                />
                <StyledButton
                  onClick={validateForm(sendMail)}
                  name="Send"
                  isLoading={sendMailLoading}
                />
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  multiple
                />
              </Flex>
            </InputSection>
          </Form>
        </div>
        {uploadedFiles.length > 0 && (
          <div className="col-4 ml-10 mt-30" style={{ position: 'relative' }}>
            <br />
            <Form
              title={`Attachements(${uploadedFiles.length})`}
              renderActionButtons={null}
            >
              <Flex justifyContent="flex-start" flexDirection="column">
                {uploadedFiles.map((data, index) => {
                  return (
                    <FileAvatar key={data?.name}>
                      <Icon name="attach" /> {data?.name}{' '}
                      <Popup
                        content={tr('Remove this file')}
                        size="mini"
                        trigger={
                          <Icon
                            name="times"
                            onClick={() => {
                              removeFile(data?.name);
                            }}
                            circular
                            inverted
                            color="red"
                            size="small"
                          />
                        }
                      />
                    </FileAvatar>
                  );
                })}
              </Flex>
            </Form>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default compose(
  withMail,
  withAccount,
  withTranslation,
  withUserDefaultSpace,
  withFormValidation,
)(Compose);
