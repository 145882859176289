import React, { Component }  from 'react';
import Img from "../Img";
import styled from "styled-components";


import Paragraph from "../typography/p";



const UserIconDiv = styled.div`
    display:flex;
    align-items:center;
    margin-top:15px;
    /* justify-content:center; */

    .image{
        width:60px;
        height:60px;
        border-radius:50%;
        overflow:hidden;
    }
    .name{
        padding:5px;
        margin-left:15px;
    }
`


const UserIcon = props =>{
    const {src, name} = props

    return <UserIconDiv>
        <div class="image">
            <Img src={src} />
        </div>
        <div class="name">
            <Paragraph color="#000" fontWeight="bold">{name}</Paragraph>
        </div>
    </UserIconDiv>
}

export default UserIcon