import React from 'react';
import styled from 'styled-components';
import EmptyState from 'components/common/EmptyState';
import refreshIconImg from '../../../../assets/img/refresh_icon_img.png';
import cancelIconImg from '../../../../assets/img/cancel_icon_img.png';

const Card = styled.div`
  flex: 1;
  margin: 0 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
`;

const CardTitle = styled.h4`
  margin: 0 0 10px 0;
  font-size: 16px;
  color: #000;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  border-bottom: 1px solid #e1e3e8;
`;

const Value = styled.div`
  font-size: 40px;
  color: ${(props) => props.color || '#333'};
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  padding-top: 30px;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const IconImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    background-color: #d1d3d8;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 350px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #e9e9e9;
  font-weight: bold;
  color: #454444;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background: #f5f5f5;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #e9e9e9;
  color: ${(props) => (props.type === 'link' ? '#007bff' : '#333')};
  cursor: ${(props) => (props.type === 'link' ? 'pointer' : 'default')};

  &:hover {
    text-decoration: ${(props) => (props.type === 'link' ? 'underline' : 'none')};
  }
`;

const ActionButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export const TableWrapper = styled.div`
  width: 48%;
`;

export const ProposalsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const StatsCard = ({ title, value, color }) => {
  return (
    <Card>
      <CardTitle>
        {title}
        <IconContainer>
          <Icon onClick={() => console.log('Icon clicked!')}>
            <IconImage src={cancelIconImg} alt="Icon" />
          </Icon>
          <Icon onClick={() => console.log('Icon clicked!')}>
            <IconImage src={refreshIconImg} alt="Icon" />
          </Icon>
        </IconContainer>
      </CardTitle>
      <Value color={color}>{value}</Value>
    </Card>
  );
};

export const ProposalsTable = ({ noData }) => {
  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            <TableHeader>USER</TableHeader>
            <TableHeader>TABLE TYPE</TableHeader>
            <TableHeader>ENTRY</TableHeader>
            <TableHeader>LANGUAGE</TableHeader>
            <TableHeader>ACTION</TableHeader>
          </tr>
        </thead>
        <tbody>
          {noData ? (
            <>
              <tr>
                <td colSpan="5" style={{ paddingTop: '10%' }}>
                  <EmptyState text="No data yet" button={true} />
                </td>
              </tr>
            </>
          ) : (
            <TableRow>
              <TableCell>Jones Stone</TableCell>
              <TableCell type="link">Disease</TableCell>
              <TableCell type="link">Cancer</TableCell>
              <TableCell type="link">Eu</TableCell>
              <TableCell>
                <ActionButton>Approve</ActionButton>
              </TableCell>
            </TableRow>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};
